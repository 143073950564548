import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import ContactPage from './pages/ContactPage';
import LocationPage from "./pages/LocationPage";
import MarketingPage from "./pages/MarketingPage";
import PgagreePage from "./pages/PgagreePage";
import RulePage from "./pages/RulePage";
import PrivacyconsentPage from "./pages/PrivacyconsentPage";
import ScrollToTop from "./pages/ScrollToTop";

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/terms" element={<TermsPage/>} />
        <Route path="/privacy" element={<PrivacyPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/location" element={<LocationPage/>} />
        <Route path="/marketing" element={<MarketingPage/>} />
        <Route path="/pgagree" element={<PgagreePage/>} />
        <Route path="/rule" element={<RulePage/>} />
        <Route path="/privacyconsent" element={<PrivacyconsentPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
