import React, { useState } from 'react';
import TheHeader from '../components/TheHeader';
import '../styles/TermsPage.css'

function TermsOfService() {
    const [selectedDate, setSelectedDate] = useState('2024-00-00');

    const termsByVersion = {
        '2024-00-00' : {
            content: (
                <div>
                            <h2>제 1조 (목적)</h2>
        <p>본 약관은 ㈜쉽차장(이하 ‘회사’라 함)가 운영하는 ‘쉽차장’에서 제공하는 모든 서비스를 이용함에 있어 이용자의 권리 및 의무, 기타 부수 사항에 관하여 규정함을 목적으로 합니다.</p>
        <h2>제 2조 (정의)</h2>
        <ol>
            <li>‘쉽차장’이람 함은 ‘회사’가 ‘회사’ 및 ‘주차공간 제공자’가 등록한 ‘주차공간’과 ‘제휴 주차공간’을 ‘회원’이 ‘주차공간’과 ‘제휴 주차공간’을 사용할 수 있도록 온라인 웹, 모바일 애플리케이션 등 정보통신 소프트웨어를 이용한 중개서비스, 
                IoT 기반 주차 제휴 및 예약 서비스와 이와 관련한 부가서비스 일체(이하 ‘서비스’라 함)를 말합니다.</li>
            <li>‘주차공간’이라 함은 자동차를 주차하기 위한 시설을 말하며, 주차장법 상에서 규정한 주차장과 ‘제휴 주차장’으로 분류됩니다.</li>
            <li>‘주차공간 제공자’라 함은 ‘쉽차장’을 통해 ‘주차공간’ 또는 ‘제휴 주차공간’의 정보를 온라인상에 등록하는 자로서, 상기에 구분된 주차장의 유형별로 소유 혹은 관리 및 운영 권한이 있는 주체를 의미합니다.</li>
            <li>‘제휴 주차공간’이라 함은 ‘주차공간 제공자’와 ‘회사’의 계약에 따라 운영되며, ‘쉽차장’의 IoT 하드웨어를 설치한 곳을 의미합니다.</li>
            <li>‘회원’이라 함은 ‘회사’가 정한 절차를 거쳐서 회원가입을 한 자로서, ‘쉽차장’의 정보를 제공받으며, ‘쉽차장’이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
            <li>‘컨텐츠’라 함은 ‘주차공간 제공자’ 또는 ‘회원’이 ‘주차공간’과 ‘제휴 주차공간’의 정보공유 및 판매를 위하여 게재한 모든 글, 사진, 동영상 등을 말합니다.</li>
            <li>‘IoT 하드웨어’라 함은 ‘제휴 주차공간’에 ‘회사’의 서비스를 제공하기 위해 설치된 장치로써, 예약 차량의 이외의 차량을 막는 장치를 말합니다.</li>
            <li>‘자동결제’라 함은 회원이 최초 결제수단 등의 정보를 입력한 이후 이용요금이 결제될 때마다 별도의 인증과정 없이 자동으로 결제되는 것을 말합니다. 
                자동결제와 관련한 상세한 정책은 전자지급결제대행업자의 결제서비스 이용약관 및 서비스 정책을 따릅니다.</li>
        </ol>
        <h2>제 3조 (약관의 게시와 개정)</h2>
        <ol>
            <li>회사는 본 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 이메일 등)과 함께 회원이 확인할 수 있도록 쉽차장 모바일 애플리케이션 이용 약관 페이지 및 웹사이트에 게시합니다.</li>
            <li>회사는 약관의 규제에 관한 법률, 전기통신사업법 및 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다. </li>
            <li>약관은 쉽차장 서비스 화면에 게시하거나 기타의 방법으로 서비스를 이용하고자 하는 자에게 공지하고, 회원이 이에 동의함으로써 효력이 발생합니다.</li>
            <li>쉽차장 서비스에 회원 가입신청을 하거나 네이버, 카카오의 계정을 이용하여 가입신청을 하는 경우 본 약관에 명시된 조건에 동의하여 회원 가입신청을 하는 것으로 간주합니다.</li>
            <li>회사는 필요한 사유가 발생했을 때 본 약관을 변경할 수 있습니다. 회사가 약관을 변경할 때는 적용일자 및 변경사유를 명시하여 제3항과 같은 방법으로 그 적용일자 7일 전부터 공지합니다. 다만, 약관 변경이 이용자에게 불리한 경우에는 그 적용일자 30일 전부터 제3항과 같은 방법으로 공지하며, 회원에 대하여는 회사가 필요하다고 판단하는 경우 회원가입 시 회원이 기재한 연락처 등으로 추가 개별 통지합니다. 단, 회원이 연락처 등을 기재하지 않았거나, 연락처 등이 변경되었음에도 수정하지 않아 개별 통지가 어려운 경우에는 제3항과 같은 방법으로 공지함으로써 개별 통지한 것으로 간주합니다. 본 조에도 불구하고 법령의 개정 등 긴급한 사유가 발생했을 때는 사전 고지 없이 약관을 변경할 수 있고, 
                이 때 변경된 약관은 시행일로부터 7일간 제3항과 같은 방법으로 공지하고, 명시적으로 이용자가 반대하지 않는 한 이용자는 개정된 약관에 동의한 것으로 간주됩니다.</li>
            <li>회원에게 약관 변경 적용일까지 거부 의사를 표시하지 않을 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부 의사를 표시하지 않으면, 회원이 변경 약관에 동의한 것으로 간주합니다.</li>
            <li>회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다. 변경된 약관이 시행된 이후에도 계속 당사가 제공하는 서비스를 이용하는 경우 회원은 변경된 약관에 동의한 것으로 간주합니다. 
                이와 별개로 변경 전 약관을 적용할 수 없는 사정이 있는 경우 회사는 회원을 탈퇴 처리할 수 있습니다.</li>
            <li>제5항에 따라 변경 고지된 약관은 기존의 회원에게도 유효하게 적용됩니다.</li>
        </ol>
        <h2>제 4조 (약관의 해석)</h2>
        <ol>
            <li>회사는 개별 서비스에 대해서는 별도의 약관(이하 '개별약관') 및 정책(이하 '개별정책')을 둘 수 있으며, 해당 내용이 본 약관과 충돌할 경우에는 개별약관 및 개별정책이 우선하여 적용됩니다.</li>
            <li>약관의 해석은 한국어로 작성된 내용을 기준으로 하며, 영어를 비롯한 다른 언어로 번역되어 한국어 내용과 상이한 해석이 발생한 경우, 한국어 해석에 따릅니다.</li>
            <li>약관에 정하지 아니한 사항과 본 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.</li>
        </ol>
        <h2>제 5조 (이용계약의 성립)</h2>
        <ol>
            <li>본 약관에 따른 이용계약은 이용자가 본 약관에 대한 동의와 함께 서비스에 대한 이용신청을 하고 회사가 이를 승낙함으로써 성립합니다.</li>
            <li>회사는 다음 각 호에 해당하는 사유가 있을 때에는 이용자의 서비스 이용신청을 유보하거나 거부할 수 있습니다.
            <ol className="nested">
                <li>가. 기술적 문제로 서비스 제공이 불가능한 경우</li>
                <li>나. 천재지변 또는 보안상 문제 기타 이에 준하는 사유가 있는 경우</li>
                <li>다. 회사에 긴습한 사정이 있거나, 회원의 정당한 권리 침해 방지 등을 위해 필요한 경우</li>
                <li>라. 기타 회사의 경영사정 상 이용자의 서비스 이용을 제한하고자 하는 경우</li>
                <li>마. 본 약관 제 6조 4항 각호의 사유가 있는 경우</li>
                <li>바. 본 약관 제 6조 5항 각호의 사유가 있는 경우</li>
                <li>사. 위 가목 내지 바목에 준하는 사유가 있는 경우</li>
            </ol>
            </li>
        </ol>
        <h2>제 6조 (회원 가입 및 자격)</h2>
        <ol>
            <li>회사가 제공하는 회원가입 신청양식에서 요구하는 사항을 기록하여 회사에 회원가입 신청을 할 수 있습니다.</li>
            <li>회원가입 신청 시 기재하는 정보는 반드시 본인의 정보를 입력하여야 하며, 타인의 정보를 도용하거나 허위의 정보를 등록한 회원은 서비스 이용과 관련하여 아무런 권리를 주장할 수 없으며, 
                법적인 보호를 받을 수 없습니다. 가입 신청자가 사실과 다른 정보를 기입하는 경우 회사는 
                서비스 이용을 일시정지하거나 영구정지 및 이용 계약을 해지할 수 있습니다. 이로 인하여 회사 또는 제3자에게 발생한 손해는 허위의 정보를 등록한 회원이 모든 책임을 집니다.</li>
            <li>회사가 정한 양식에 따라 모든 정보를 정확히 기재해 회원가입을 신청하거나 정보제공을 동의하는 경우 회사는 회원가입을 승낙합니다.</li>
            <li>회사는 다음과 같은 사유가 발생한 경우 회원가입신청에 대한 승낙을 거부하거나 사후회원자격 박탈 등의 조치를 취할 수 있습니다.
                <ol className="nested">
                    <li>가. 다른 사람의 명의를 사용하여 가입 신청한 경우 또는 다른 회원의 ID 내지 비밀번호를 부정하게 사용하는 경우</li>
                    <li>나. 회원가입신청 시 필수 작성 사항을 허위로 기재한 경우</li>
                    <li>다. 회사에 의하여 이용계약이 해지된 날로부터 3개월 이내에 재이용신청을 하는 경우</li>
                    <li>라. 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우</li>
                    <li>마. 관계법령의 위반을 목적으로 신청하거나 그러한 행위를 하는 경우</li>
                    <li>바. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하거나 그러한 행위를 하는 경우</li>
                    <li>사. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</li>
                    <li>아. 본 약관 제13조 회원의 의무를 지키지 않는 경우</li>
                    <li>자. 1회 입출차 가능한 주차장 또는 주차면을 여러차례 입출차하며 이용하는 경우</li>
                    <li>차. 주차장에 설치된 회사의 설치물을 고의로 훼손 또는 파손하는 경우</li>
                    <li>카. 여신전문금융업법에 위반되는 신용카드 부정 사용인 경우</li>
                    <li>타. 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
                    <li>파. 회사에 대한 금전적인 채무가 존재하는 경우</li>
                </ol>
            </li>
            <li>회사는 아래 사유가 있는 경우 그 사유가 해소될 때까지 회원 가입 신청에 대한 승낙을 유보할 수 있습니다.
                <ol className="nested">
                    <li>가. 서비스 설비의 여유가 없는 경우</li>
                    <li>나. 기술 상 지장이 있다고 판단되는 경우</li>
                    <li>다. 회원 가입 신청 시 필수 작성 사항을 누락하거나 오기하여 신청하는 경우</li>
                    <li>라. 회사가 정한 본인 인증 절차를 완료하지 않은 경우</li>
                    <li>마. 본 약관 제 5조 2항의 사유가 있는 경우</li>
                </ol>
            </li>
            <li>회사가 회원 자격을 박탈하는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 사전 통지하여 소명할 기회를 부여합니다. 
                단, 회원의 귀책사유로 통지할 수 없는 경우 통지를 생략할 수 있습니다.</li>
            <li>제6조 4항의 사유로 회원 자격이 박탈되는 경우 적립금을 제외한 쿠폰은 소멸되며 회사는 이에 대해 책임지지 않습니다.</li>
        </ol>
        <h2>제 7조 (회원 탈퇴 등)</h2>
        <ol>
            <li>회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 법령과 회사 내부 규정이 정하는 바에 따라 이를 처리하여 회원을 탈퇴 처리합니다.
        <br/>단, 회사는 회원의 신청에 따른 서비스 이용계약 해지를 처리하는 과정에서 회사의 고의 또는 중대한 과실 없이 회원에게 발생하는 손해에 대하여 책임을 부담하지 아니합니다.</li>
            <li>회원은 다음 각 호의 어느 하나에 해당하는 경우 탈퇴 처리가 거절될 수 있습니다.
                <ol className='nested'>
                    <li>가. 회사에 대한 금전적인 채무가 존재하는 경우</li>
                    <li>나. 관련법령 및 회사의 개인정보 처리방침에서 정한 바에 따라 회원에 관한 정보를 저장, 보유, 보관, 기록해야 하는 경우</li>
                    <li>다. 회사와 회원간 또는 회원과 제3자(다른 회원을 포함함) 사이에 분쟁이 있거나 분쟁이 있을 것으로 예상되는 경우</li>
                </ol>
            </li>
            <li>제6조 4항 각 호에 해당하는 행위 및 기타 회원의 책임 있는 행위를 기반으로 발생한 손해배상 및 업무 처리가 완료되지 않은 경우, 
                이용자가 본 약관상 정한 의무를 포함하여 총 2회 이상 의무를 위반하거나, 본 약관에서 정하는 바에 따라 회사가 제공하는 서비스의 전부 또는 일부의 중단 또는 
                이용 제한의 조치가 이루어졌음에도 불구하고 
                그 중단 또는 이용 제한을 해제할 수 있는 조건이 성취되지 아니하는 경우, 회사는 이용 계약을 해지할 예정임을 이용자에게 통지하고 이용 계약을 해지할 수 있습니다.</li>
            <li>이용 계약이 해지되는 경우, 회사는 관련 법령, 개인정보처리방침 및 회사의 내부 규정에 따라 회원의 정보를 보유하는 경우를 제외하고 회원의 정보를 삭제합니다. 회사가 회원의 정보를 삭제한 경우 회원은 회사가 보유하고 있는 회원 정보의 제공을 요청할 수 없습니다.</li>
            <li>회원은 본 약관 제10조에서 규정한 바에 따라 환불받을 수 있을 뿐, 이 외의 방법으로 환불을 요청할 수 없습니다.</li>
            <li>이용 계약이 해지된 경우라도 이용자는 다시 회사에 대하여 이용 계약의 체결을 신청할 수 있습니다. 다만, 다시 이용 계약을 체결함에 있어 시간적 제한 및 본 약관 상의 제한 등이 따를 수 있습니다.</li>
            <li>이용 계약의 해지는 회사의 이용자에 대한 손해배상 청구에 영향을 미치지 않습니다.</li>
        </ol>
        <h2>제 8조 (서비스의 제공)</h2>
        <ol>
            <li>서비스는 회사의 업무상 또는 기술상의 장애, 기타 특별한 사유가 없는 한 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
    <br/>단, 회사는 개별 서비스의 내용에 따라 그 제공시간을 달리 정할 수 있습니다.</li>
            <li>회사는 약관이 정한 바에 따라 안정적으로 서비스를 제공하기 위해 노력하며, 부득이한 이유로 서비스가 중단되면 지체 없이 수리 복구할 수 있도록 노력합니다.
    <br/>단, 시스템 점검, 천재지변, 비상사태, 기타 부득이한 경우에는 서비스를 일시적으로 중단할 수 있으며, 회원은 이에 대하여 회사에 손해배상 내지 보상을 청구할 수 없습니다.</li>
            <li>제휴를 통해 제공되는 서비스의 경우 주차장 또는 운영사의 사정에 따라 서비스를 변경 또는 중단할 수 있습니다.</li>
            <li>회사가 이용자에게 제공하는 서비스는 아래와 같습니다.
                <ol className='nested'>
                    <li>가. 주차공간에 관한 이름, 위치, 이용시간, 금액, 연락처 등의 정보</li>
                    <li>나. 제휴 주차공간 예약 서비스</li>
                    <li>다. 주차공간 사용에 대한 결제 이용 서비스</li>
                    <li>라. 회원 푸쉬 알림 서비스</li>
                    <li>마. 기타 회사가 자체 개발하거나 타 회사와의 협력계약 등을 통해 회원들에게 제공하는 일체의 서비스</li>
                </ol>
            </li>
            <li>회사는 본 조 제4항의 서비스만 제공할 뿐, 주차공간(주차장)을 제공하지 않으며, 주차공간(주차장)은 주차공간 제공자가 제공하며 세부적인 이용조건도 
                주차공간 제공자가 정하는 바에 따릅니다.</li>
            <li>회사는 주차공간 및 제휴 주차공간의 설치, 관리, 운영자가 아니며, 주차공간 및 제휴 주차공간의 설치, 관리, 운영자에게 적용되는 관련 법령상의 규율, 의무, 책임이 적용되지 않습니다.</li>
            <li>주차공간 및 제휴 주차공간, 차량의 관리, 주차요금 수준, 주차장 이용에 따른 제반 사항은 주차공간 제공자가 정하는 바에 따라 처리하며, 이에 따른 의무와 책임도 회사가 아닌 주차공간 
                제공자가 부담합니다. 회원은 세부적인 주차공간 및 제휴 주차공간 이용조건을 주차공간 제공자를 통해 별도로 확인하여야 하며, 관련하여 회사는 책임을 지지 않습니다.</li>
            <li>주차공간 및 제휴 주차공간에서 발생하는 각종 사고(주차공간 관리 소홀로 인한 차량의 파손 등)에 대해서는 회사는 무관하며, 회사와 관련이 없는 사정으로 인하여 
                발생한 이용자의 손해, 회사와 관련이 있다고 하더라도 회사의 고의 또는 중대한 과실이 있지 않은 이상 관련 이용자의 손해를 보상하거나 배상하지 않습니다.
            명확히 하면, 회사는 주차공간 제공자와 이용자 내지 회원 간의 분쟁에 관하여 어떠한 책임도 부담하지 않습니다.</li>
            <li>회원이 제휴 주차공간 이용 시, IoT 하드웨어를 고의 또는 과실로 훼손/파손을 하는 경우 또는 이러한 행위로 회사가 제휴 주차공간 제공자에게 배상책임을 부담하는 경우 회사는 회원에게
            배상책임을 요구할 수 있습니다.</li>
            <li>쉽차장 서비스에서 제공하는 주차공간 및 제휴 주차공간 정보 제공과 관련해 회사는 정확한 정보를 제공하기 위해 노력을 다합니다. 
                다만, 세부적인 이용조건은 주차공간 제공자의 정책에 따라 수시로 변경될 수 있고, 이러한 변경으로 인한 정보 불일치에 대해서는 회사는 고의 또는 중대한 과실이 없는 이상 책임을 지지 않습니다.</li>
            <li>회사는 안정적인 서비스를 제공하기 위해 언제든지 서비스 내지 서버 점검을 할 수 있고, 회원은 서비스 내지 서버 점검이 종료된 이후 서비스를 이용할 수 있습니다.</li>
            <li>회사는 더 나은 서비스를 위하여 이용자에게 서비스의 이용과 관련된 각종 고지, 관리 메시지를 발송할 수 있으며, 광고성 정보 등의 경우에는 이용자로부터 별도로 동의를 받은 후 발송할 수 있습니다.</li>
            <li>이용자가 서비스를 이용하는 과정에서 발생하는 통신 및 금융비용은 이용자가 이용하고 있는 별도의 통신 및 금융서비스의 이용 대가로, 회사와 아무런 관련이 없습니다.</li>
            <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 
                이 경우 회사는 사전에 쉽차장 서비스 화면에 게시하거나 기타의 방법을 통하여 이용자에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
            <li>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간 동안 거래가 중단, 정지될 수 있습니다. 
                정기점검시간은 쉽차장 서비스 화면에 게시하거나 기타의 방법을 통해 공지한 바에 따릅니다.</li>
            <li>회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.</li>
            <li>회사는 서비스를 변경할 경우 변경내용과 적용일자를 명시하여 사전에 공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.</li>
            <li>회원은 서비스 변경에 동의하지 않을 경우 회사에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.</li>
            <li>회사는 서비스 제공과 관련한 회사 정책의 변경 등 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있으며, 
                이에 대해 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</li>
        </ol>
        <h2>제 9조 (서비스 이용방법)</h2>
        <ol>
            <li>본 약관에 동의한 회원은 쉽차장 서비스에서 제공하는 주차공간 및 제휴 주차공간에 관한 정보를 열람할 수 있습니다.</li>
            <li>회원은 제휴 주차공간 이용 시 회사가 정한 모바일 애플리케이션을 통한 예약을 통해 제휴 주차공간을 예약하고 주차 종료 버튼을 눌러 사용한 시간만큼의 결제를 회사가 정한 모바일 애플리케이션을 통해 자동결제합니다.
                <ol className='nested'>
                    <li>가. 제휴 주차요금
                        <ol className='nested'>
                            <li>1） 예약 시, 주차 시작 시간은 예약이 완료된 시점부터이며, 예약 시 설정한 시간만큼이 아니라 사용한 시간만큼 결제됩니다. 또한, 주차 편의를 위해 10분의 무료시간이 추가됩니다. 
                                단, 다른 이용자가 먼저 해당 시간을 예약했을 경우, 이용에 제한이 있을 수 있습니다.</li>
                            <li>2） 회원은 제휴 주차공간을 이용하고 나서 실제 제휴 주차공간을 사용한 시간만큼 요금이 자동결제됩니다. 단, 제휴 주차공간에서 정한 주차요금 정책에서 기본시간 미만으로 주차한 경우에는
                            기본시간에 해당하는 기본요금이 결제됩니다. 기본요금 없이 초과시간 다위에 대한 요금만 있을 경우, 초과시간 단위 미만으로 주차한 경우는 초과시간 단위에 해당하는 요금으로 자동결제됩니다.</li>
                            <li>3） 시간 연장 없이 예약된 시간을 초과해 제휴 주차공간을 사용하는 경우, 초과한 시간에 대해 페널티 요금이 청구됩니다.</li>
                            <li>4） 주차 종료 시에는 차량을 주차면에서 완전히 출차한 다음 모바일 애플리케이션에서 ‘주차 종료하기’버튼을 눌러야지만 주차가 종료되며 사용한 시간만큼 자동결제가 됩니다. 
                                ‘주차 종료하기’버튼을 누르지 않으면, 사용자가 제휴 주차공간을 사용한 시점부터 운영 종료시간까지 하루 단위 요금이 결제됩니다.</li>
                            <li>5) 제휴 주차요금은 제휴 주차공간 제공자의 정책에 따라 이용요금이 상이할 수 있습니다.</li>
                            <li>6） 회원이 제휴 주차공간을 부당하게 이용하거나 부정주차 등으로 단속이 된 경우, 회사는 이를 책임지지 않으며, 관련하여 회원에게 부과된 범칙금, 견인료, 보관료 등은 모두 회원이 부담하여야 합니다.</li>
                            <li>7） 주차권(시간권)의 경우 주차공간 제공자가 정한 주차공간 이용수칙에 맞게 이용했을 경우에 한정되어 사용할 수 있고, 회원이 주차공간 제공자가 정한 주차공간 이용수칙에 맞게 이용하지 않은 경우 주차권의 사용이 제한될 수 있습니다. 
                                회원은 주차공간 제공자가 정한 주차공간 이용수칙을 확인하여 준수하여야 하고, 그 미준수에 따른 제한에 대해서는 회사가 책임지지 않습니다.</li>
                            <li>8） 원의 개인 신용등급 및 연체 등의 문제로 인해 서비스 요금이 정산되지 않을 경우에는 이전 사용 내역이 모두 정산될 때까지 서비스 이용 자격이 일시정지되며, 지속적인 문제가 발생할 경우에는 회원 자격이 박탈될 수 있습니다. 
                                이러한 회원의 서비스 사용권한에 대한 판단 기준은 회사의 내부 운영 규정에 근거합니다.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>회사가 제공하는 주차권 등 유료 상품은 아래와 같이 구분됩니다. 단, 세부적인 내용은 회사 또는 주차공간 제공자의 사정에 따라 변경될 수 있습니다.
                <ol className='nested'>
                    <li>가. 주차권은 시간권이 있으며 1회성 주차권으로, 1회 출차를 진행하면 소멸됩니다.</li>
                    <li>나. 시간권은 주차장에 입차한 시간 기준으로 사용자가 사용한 시간만큼 이용할 수 있는 상품으로, 구체적인 상품 내용은 주차공간 제공자에 따라 변경될 수 있습니다.</li>
                    <li>다. 구매한 주차권 등 상품에서 사용 완료 후 모바일 애플리케이션에서 주차 종료 버튼을 누르지 않는다면 사용한 시점부터 운영 종료시간까지 하루 단위 요금이 결제됩니다.</li>
                </ol>
            </li>
            <li>회원은 결제 시 본인 명의의 결제 수단을 사용해야 하며, 타인의 결제 수단을 사용할 수 없습니다. 
                결제서비스 이용 시 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 회원이 부담해야 합니다.</li>
            <li>회원은 결제서비스 이용 시 정당하고, 적법한 사용 권한을 가지고 있는 결제 수단을 사용하여야 하며, 회사는 그 여부를 확인할 수 있습니다. 한편 회원은 결제단계별로 우선적으로 활용할 결제 수단을 스스로 선택할 수 있습니다. 
                또한, 회사는 회원 결제 수단의 적법성 등에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있습니다.</li>
            <li>회원이 서비스를 이용하는 경우, 자동결제와 관련한 다음 각 호에 동의한 것으로 간주합니다.
                <ol className='nested'>
                    <li>1) 서비스 이용요금에 대해 별도의 인증과정 없이 결제를 하겠다는 의사표시를 한 것으로 봅니다.</li>
                    <li>2) 자동결제 시점에 회원의 신용카드 등의 유효성, 한도 등의 문제로 결제실패가 발생한 경우에 미수로 처리되며, 해당 미수 금액이 정상적으로 결제되기 전까지는 해당 회원은 서비스 이용이 제한됩니다.</li>
                    <li>3) 제2호에 따라 미수가 발생한 경우, 회사는 회사가 정한 정책에 따라 회원에 대한 별도 고지 없이 회원이 등록한 결제수단으로 재결제 시도를 진행할 수 있습니다.</li>
                    <li>4) 결제 시점에 시스템 장애로 인하여 결제 실패가 된 경우에는 시스템 정상화가 된 시점에 회원에 대한 별도 고지 없이 다시 결제를 진행합니다.</li>
                </ol>
            </li>
        </ol>
        <h2>제 10조 (손해배상 요금 및 페널티 제도)</h2>
        <ol>
            <li>회원은 회사에서 규정한 서비스 요금정책을 준수하여야 하며, 서비스 이용 중 아래 해당하는 손해배상금이나 페널티 요금이 부과될 수 있습니다. 회원은 회사가 인정하는 방법으로 서비스 및 손해배상금 등을 결제하여야 합니다.
                <ol className='nested'>
                    <li>가. 손해배상 요금
                        <ol className='nested'>
                            <li>1） 회원이 고의 또는 과실로 인해 설치된 IoT 하드웨어가 훼손/파손된 경우, 회원은 그로 인하여 발생한 손해를 배상해야 할 책임이 있습니다.</li>
                            <li>2） 회사는 회원이 규정사항 위반 시 본 약관 제10조 페널티 제도에 의거하여 페널티 금액을 부과할 수 있으며, 이와 별도로 IoT 하드웨어 수리비, 소송비용, 변호사 비용,
                                기타 이와 관련하여 회사가 주차공간 제공자 등 제3자에게 배상 내지 보상하게 된 금액 등 발생 실비를 추가로 청구할 수 있습니다.</li>
                            <li>3） 손해배상금은 전자결제를 통한 결제 또는 회사와 협의한 결제 수단으로 지불할 수 있습니다.</li>
                            <li>4） 회원이 본 약관 및 각 조항을 준수하지 않았을 경우, 회사는 손해배상 요금 등 각종 수수료를 부과하는 것 이외에 계약을 해지할 수 있는 권리를 가집니다.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을지지 않습니다.</li>
            <li>회원이 서비스를 이용함에0 있어 행한 불법행위로 인해 회사가 해당 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종 이의제기를 받는 경우 해당 회원은 회사의 고의 또는 중과실이 없는 한 회사를 면책시켜야 하며, 
                회사가 면책되지 못한 경우는 회원은 그로 인해 회사에게 발생한 모든 손해를 배상해야 합니다.</li>
            <li>회원이 본 약관에 따른 금전 채무의 이행을 지체한 경우, 연 15%의 약정이율을 계산한 지연손해금을 지급해야 합니다.</li>
            <li>회사는 고의 또는 중대한 과실이 없는 이상 회원에 대하여 발생한 손해에 대해 책임을지지 않으며, 본 약관 제15조에 따라 면책됩니다.</li>
            <li>회사는 건전한 주차 이용 문화 조성을 위해 페널티 제도를 운용하고 있습니다. 페널티 금액은 피해 발생으로 인한 최소 운영 비용을 근거로 책정되며, 패널티 제도는 언제든지 그 필요성 및 활용성에 따라 개정될 수 있습니다
                <ol className='nested'>
                    <li>가. 페널티 제도
                        <ol className='nested'>
                            <li>1） 페널티 항목 외 개별약관 및 개별정책에 따라 서비스 운영상 저해가 되는 이용행태 및 서비스 취지, 목적에 맞지 않는 불법적, 비정상적 이용 등에 대해서는 내부심사 후 적절한 조치가 취해질 수 있습니다.</li>
                            <li>2） 각 항목들의 위반사항을 지속적으로 발생시키는 회원에 대해서는 이용자격 심사를 통해 조치가 취해집니다.</li>
                            <li>3） 페널티 금액을 부과할 경우 회원에게 고지 및 협의 후 결제 및 조치를 진행합니다.</li>
                            <li>위반사항: 서비스 이용 시간 내 IoT 장비 파손 미신고
                                <li>- 페널티 요금 : 발생 비용</li>
                                <li>* 장비 수리/사고 처리 비용 + 영업손실</li>
                                <li>* 3회 누적 발생 시 회원자격 재심사</li>
                            </li>
                            <li>위반사항: 서비스 이용 시간 내 IoT 장비 파손, 수리 및 분해 등
                                <li>- 페널티 요금 : 발생 비용</li>
                                <li>* 장비 수리 비용 + 영업손실</li>
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>회사는 서비스와 관련하여 발생한 미납 요금, 페널티 금액, 손해배상 요금 등에 대한 회원의 채무에 대해 등록한 결제 카드 등의 정보로 전부 또는 일부 결제를 진행할 수 있습니다. 해당 채무가 지속 연체될 경우 회원을 상대로 보전처분, 본안소송 제기, 
                강제집행 절차의 착수 등 채무 변제를 위한 법적 조치에 들어갈 수 있습니다. 단, 사전에 회사와 회원이 협의하면 협의한 결제 수단으로 지불할 수 있습니다.</li>
        </ol>
        <h2>제 11조 (환불)</h2>
        <ol>
            <li>회사는 회원이 구매한 서비스의 제공이 불가능할 경우 발견한 날로부터 7일 이내 회원에게 통지 후 환불할 수 있습니다.</li>
            <li>회사는 과오금이 발생한 경우 또는 정당한 환불 요청이 있는 경우, 회원이 결제한 방법과 동일한 방법으로 환불을 진행합니다. 단, 환불 처리 시점이 취소가 가능한 기간을 경과하였거나, 
                기타 사유로 인해 취소 처리가 불가한 경우, 회사는 회원 명의의 계좌로 환불 금액을 송금할 수 있습니다.</li>
            <li>회사는 제4조 1항에 따라 서비스별로 별도 약관 및 이용정책에 따른 환불 규정을 정할 수 있으며, 이 경우 개별약관 및 이용정책 상의 환불규정이 우선 적용됩니다. 서비스별 환불 규정은 아래와 같이 구분됩니다.
                <ol className='nested'>
                    <li>가. 제휴 주차권
                        <ol className='nested'>
                            <li>1） 제휴 주차권이란 제휴 주차공간을 회사가 제공하는 모바일 애플리케이션을 통해 사용하겠다 예약한 경우를 말합니다.</li>
                            <li>2） 제휴 주차권은 예약 즉시 주차가 시작되며, 회원은 결제 후 10분 이내에 직접 결제 취소를 할 수 있습니다. 회사는 사용자의 주차 편의를 위해 무료로 10분의 주차 시간을 제공하고 있습니다. 
                                따라서, 사용자가 환불 가능한 시간도 무료로 제공되는 10분의 무료 시간으로 한정됩니다. 단, 예약한 주차면에 부정주차로 인해 주차가 불가할 경우 주차권은 환불 및 부분환불이 가능합니다.</li>
                            <li>3） 반복 입, 출차가 불가능하며 주차면을 이용하지 못한 시간에 대한 부분 환불은 불가능합니다.
                            단, 예약한 주차면에 부정 주차로 인해 주차가 불가할 경우 주차권은 환불 및 부분환불이 가능합니다.</li>
                            <li>4) 사용자는 주차를 마친 후, 차량을 주차면에서 완전히 출차한 다음 모바일 애플리케이션에서 주차 종료 버튼을 눌러서 주차 종료를 해야합니다.
                            주차 종료 버튼을 누르지 않으면 사용자가 이용한 시점부터 제휴 주차공간 하루 단위의 운영 종료 시점까지의 요금이 부과됩니다.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
        <h2>제 12조 (서비스 이용)</h2>
        <ol>
            <li>회원은 회원가입 절차가 완료된 이후 제8조 4항에서 열거한 서비스를 이용할 수 있습니다.</li>
            <li>회원은 서비스 이용 시 불편사항에 관해 언제든지 회사에 문제 제기를 할 수 있습니다. 회사는 이용자의 문제 제기가 정당하다고 인정하는 경우 이를 해결하기 위해 노력합니다.</li>
            <li>회원의 아이디와 비밀번호에 대한 모든 관리책임은 회원에게 있고, 회원은 자신의 아이디를 타인에게 양도, 증여할 수 없습니다. 회원이 등록한 아이디 및 비밀번호를 이용하여 발생한 회원, 회사 또는 제3자에 대한 손해 및 손실에 관한 모든 책임은 해당 회원에게 있습니다.</li>
            <li>아래의 경우 이용자의 서비스 이용이 제한될 수 있습니다.
                <ol className='nested'>
                    <li>가. 다른 이용자의 정보를 무단으로 수집, 이용하려고 하거나 한 경우</li>
                    <li>나. 행위, 서비스를 이용자의 사업 또는 영업 목적으로 이용하는 행위를 하려고 하거나 한 경우
         <br/>단, 회사와 별도의 계약을 체결한 경우에 한하여 예외적으로 허용될 수 있습니다.</li>
                    <li>다. 공서양속 및 법령에 위반되는 내용의 정보 등을 발송하거나 게시하는 행위를 하려고 하거나 한 경우</li>
                    <li>라. 계정 정보 대여 또는 양도, 담보의 제공 행위를 하려고 하거나 한 경우</li>
                    <li>마. 위 가~라에 준하는 사유가 있는 경우</li>
                </ol>
            </li>
            <li>회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 
    서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위는 일체 금지합니다.</li>
            <li>회원이 관련 법령, 회사의 모든 약관 또는 정책을 준수하지 않는 경우, 회사는 회원의 위반행위 등을 조사하거나 이용자의 서비스 이용을 잠시 또는 계속하여 중단할 수 있고, 서비스 재가입에 제한을 둘 수 있습니다.</li>
            <li>회원이 서비스에 일정 기간 이상 로그인하지 아니할 경우 이용자의 계정이 도용 당하는 등의 사정으로 인하여 이용자에게 발생할 수 있는 피해를 예방하기 위해, 회사는 이용자의 서비스 이용에 필요한 추가 정보를 요구할 수 있고,
    특히 12개월 이상 로그인하지 아니할 경우 회사는 계정 정지에 따른 절차에 착수할 수 있습니다.</li>
        </ol>
        <h2>제 13조 (회사의 의무)</h2>
        <ol>
            <li>회사는 관련법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않고, 회원들에게 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.</li>
            <li>회사는 쉽차장 서비스와 관련한 불편사항 및 문제에 대해 접수되는 경우 이를 신속하게 처리하여야 하고, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 즉시 통보합니다.</li>
            <li>회사는 회원의 컨텐츠가 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 저작권법 등 관련 법령에 위반되는 내용을 포함하고 있음을 인지한 경우, 관련 법령에 따라 조치합니다. 또한 권리자는 회사에 관련 법령이 정한 절차에 따라 해당 게시물의 삭제 등을 요청할 수 있습니다.</li>
            <li>회사는 항상 회원의 정보를 포함한 개인신상정보에 대하여 관리적, 기술적 안전조치를 강구하여 정보보안에 최선을 다합니다.</li>
            <li>회사는 소비자 보호단체 및 공공기관의 소비자 보호업무의 추진에 필요한 자료 등의 요구에 적극 협력합니다.</li>
        </ol>
        <h2>제 14조 (회원의 의무)</h2>
        <ol>
            <li>회원은 쉽차장 서비스를 이용하기 전에 이용방법 및 조건 등을 확인하여야 합니다. 이용방법 및 조건 등을 확인하지 않고 서비스를 이용하여 발생한 손실, 손해에 대한 책임은 최원 본인이 부담하여야 합니다.</li>
            <li>회원은 본 약관 및 회사가 서비스와 관련해 통지 또는 고지하는 내용과 관련법령 등을 준수해야 하며, 본 약관 및 통지 또는 고지 내용이나 관련법령을 위반하거나 이행하지 않아 발생하는 손실, 손해에 대한 책임은 회원 본인이 부담하여야 합니다. 회사는 필요한 경우 회원의 관련법령 등의 위반사실을 정부기관 또는 사법기관에 직접 통지하거나, 
                이러한 위반사실을 인지한 거래당사자에게 수사기관 제보 등 적절한 조치를 취할 것을 안내할 수 있습니다.</li>
            <li>회원은 자신이 게시한 정보에 대해 무한 책임을 집니다.</li>
            <li>회원은 계정 관리를 철저히 하고 이를 제3자에게 이용하게 해서는 안되며, 회원 본인의 계정을 도난당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야 합니다. 회사의 고의 또는 과실이 없는 한, 
                회원의 계정 관리소홀, 부정사용 등에 의하여 발생하는 책임과 불이익은 회원이 부담하여야 합니다.</li>
            <li>회원은 유료 서비스 등에 대한 결제 시 반드시 정당하고 적법한 사용권한을 가진 결제 수단을 사용해야 하며, 회사는 그 여부를 확인할 수 있습니다. 회사는 회원 결제수단의 적법성 등에 대한 확인이 완료될 때까지 쉽차장 서비스의 제공을 중단할 수 있고, 적법한 권한이 없는 결제수단을 임의로 사용해 발생하는 회사, 
                결제 수단의 적법한 소유자, 전자결제대행업자 또는 서비스 제공자 등의 손실과 손해에 대한 책임은 회원에게 있습니다.</li>
            <li>회원은 다음 행위를 해서는 안됩니다.
                <ol className='nested'>
                    <li>가. 회원가입 신청 또는 변경 시 허위 내용의 등록</li>
                    <li>나. 타인의 정보 도용</li>
                    <li>다. 회사가 게시한 정보의 변경</li>
                    <li>라. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                    <li>마. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                    <li>바. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                    <li>사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</li>
                    <li>아. 영리적 또는 개인의 이익을 위한 비정상적인 서비스 이용 및 불법적이거나 부당한 행위</li>
                    <li>자. 이용 의사가 없음에도 반복적인 예약 및 취소를 통해 회사의 서비스 제공을 방해하는 행위</li>
                    <li>차. 고객센터 문의 시 욕설, 폭언, 성희롱, 반복적인 민원을 통해 업무를 방해하는 행위</li>
                    <li>카. 위 가목 내지 차목에 준하는 행위</li>
                </ol>
            </li>
            <li>회원은 본 조항의 의무 및 책임을 다하지 않을 경우 서비스 이용 제한 또는 회원 자격이 상실될 수 있습니다.</li>
            <li>회원은 고의나 과실로 회사 또는 제3자에게 손해를 입힐 경우 배상할 책임이 있습니다.</li>
            <li>회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</li>
            <li>회원은 회사 서비스를 해킹하거나 불법 프로그램을 업로드 해서는 안 됩니다.</li>
        </ol>
        <h2>제 15조 (면책)</h2>
        <ol>
            <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
            <li>회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임지지 않습니다.</li>
            <li>회사 이외의 타 사업자가 제공하는 정보에 의해 발생하는 피해나 서비스의 장애로 인한 경우에 대하여는 회사는 책임을 지지 않습니다.</li>
            <li>주차장은 주차공간 제공자의 책임 하에 관리 및 운영되고, 회사는 통신판매중개자로서 서비스 운영 상의 문제를 제외한 주차장의 하자, 부실, 각종 사고 등으로 인한 책임은 주차공간 제공자에게 귀속되며 회사는 어떠한 책임도 부담하지 않습니다.</li>
            <li>회사가 제공한 서비스를 통해 취득한 정보나 서비스에 게시된 다른 회원의 게시물을 통해 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 하고자 할 때에는 반드시 사전에 전문가와 상의할 것을 권고합니다. 
            서비스 내에서 이용자가 게시한 정보, 의견 및 자료 등은 회사와 아무런 관련이 없으며, 게시물의 내용과 관련하여 발생한 법적 책임은 전적으로 해당 게시물을 게시한 이용자 및 이를 열람한 이용자에게 있습니다. 
            이용자간 또는 이용자와 제3자 간에 서비스를 매개하여 발생한 분쟁에 관여할 법적 의무가 없으며, 이와 관련하여 어떠한 책임도 지지 않습니다.</li>
            <li>본 약관의 적용은 이용계약을 체결한 이용자에 한하며 이용자 이외의 제3자에게 어떠한 배상, 소송 등에 대하여 회사는 책임을 면합니다.</li>
            <li>회사는 서비스 제공을 위하여 회사의 서버를 점검하는 경우 서비스 제공 지연 등에 관한 책임이 면제됩니다.</li>
        </ol>
        <h2>제 16조 (개인정보 보호 등)</h2>
        <ol>
            <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보, 위치정보 등(이하 ‘개인정보 등’이라 함)을 보호하기 위하여 관련법령에 따라 보안 시스템 등을 갖추고 개인정보 등 보호 정책을 실시합니다.</li>
            <li>회사는 회원의 개인정보 등을 관련법령 상 필요한 경우 등을 제외하고 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게 제공하지 않습니다.</li>
            <li>회사는 수집된 개인정보 등의 취급과 관리 등의 업무를 스스로 수행함을 원칙으로 합니다. 다만, 회사는 필요한 경우 회원의 동의를 받거나 통지를 한 후 위 업무의 일부 또는
                 전부를 회사가 선정한 회사에 위탁할 수 있습니다. </li>
            <li>회원이 이용계약을 해지하는 경우, 회사는 관련법령 및 개인정보처리방침에 따라 회원의 개인정보 등을 보유하는 경우를 제외하고 해지 즉시 회원의 모든 정보를 삭제합니다.</li>
            <li>회사의 개인정보 등 보호와 관련한 상세한 내용은 회사의 홈페이지 또는 쉽차장 내에 게시하는 개인정보처리방침에 따릅니다.</li>
        </ol>
        <h2>제 17조 (통지)</h2>
        <p>회사는 본 약관 및 서비스 이용에 관한 중요한 사항을 쉽차장 서비스 화면에 게시하거나 기타의 방법으로 공지하고 필요한 경우 이용자가 등록한 
            휴대전화번호, 서신 및 기타의 방법으로 할 수 있습니다.</p>
        <h2>제 18조 (서비스 종료)</h2>
        <p>회사가 서비스를 종료하고자 할 경우에는 서비스 종료 90일 전 회원에 대한 통지를 하고 서비스를 종료할 수 있습니다.</p>
        <h2>제 19조 (분쟁의 해결)</h2>
        <ol>
            <li>회사 및 이용자는 개인정보에 관한 분쟁이 있는 경우 신속하고 효과적인 분쟁해결을 위하여 개인정보분쟁조정위원회에 분쟁의 조정을 신청할 수 있습니다.</li>
            <li>회사와 이용자 간 분쟁이 발생한 경우 회사의 주소지를 관할하는 법원을 관할법원으로 합니다.
            <br/>단, 이용자의 책임있는 사유로 분쟁이 발생한 경우 그 관리업무를 담당하는 회사 사무소 소재지의 지방법원을 관할법원으로 합니다.</li>
        </ol>
        <h2>제 20조 (법 준수)</h2>
        <p>이용자는 서비스를 이용함에 있어 국내법이나 규정 정책 및 서비스를 이용함에 있어서 필요한 국제법 및 관련 당사자국의 법을 준수할 책임이 있습니다.</p>
        <h2>부칙</h2>
        <p>본 약관은 2024년 11월 06일부터 시행됩니다.</p>
                </div>
            )
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

  return (
    <div>
    <TheHeader />
    <div className="terms">
      <h1>쉽차장 이용약관</h1>
      <select onChange={handleDateChange} value={selectedDate}>
        <option value="2024-00-00">2024년 11월 06일 갱신</option>
      </select>
      <div className='terms-content'>
        {termsByVersion[selectedDate].content}
      </div>
    </div>
    </div>
  );
}

export default TermsOfService;
